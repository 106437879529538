import { put, takeEvery, call, select } from 'redux-saga/effects'

import { incidentActions } from '.'
import { axios } from '../axiosService'
import { PayloadAction } from '@reduxjs/toolkit'
import { notificationActions } from '../notifications'

export default function* incidentSaga() {
  yield takeEvery(
    [incidentActions.getIncidents.type, incidentActions.setFilters.type],
    getIncidents
  )

  yield takeEvery(incidentActions.updateIncident.type, clearNotificationsForClosedIncidents)
}

function* getIncidents(action: PayloadAction<any>) {
  try {
    const {
      filters,
      pagination: { index }
    } = yield select(state => state.incidents)
    const params = { ...filters, index: null, userData: action.payload.filter }
    if (action.payload.loadNext) {
      params.index = action.payload.currentPage ? action.payload.currentPage - 1 : index + 1
    }

    const resp = yield call(() => axios.get('wall/incidents', { params }))
    resp.data.upsert = params && params.isResolved ? false : true
    yield put(incidentActions.getIncidentsSuccess(resp.data))
  } catch (error) {
    console.error(error)
    yield put(incidentActions.getIncidentsFailed(error))
  }
}

function* clearNotificationsForClosedIncidents(action: PayloadAction<any>) {
  if (incidentActions.updateIncident.match(action)) {
    if (action.payload.changes.isResolved === true) {
      yield put(
        notificationActions.markNotificationsForIncidentAsReadSuccess({
          incidentId: action.payload.id
        })
      )
    }
  }
}
