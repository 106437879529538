import React from 'react'
import { FormikProps } from 'formik'

import { strings } from '../../constants'

import {
  AddNoteProps,
  FormValues
} from './interfaces'

import {
  Wrapper,
  Form,
  Header,
  Label,
  Textarea,
  Button,
  CancelButton,
  CheckboxContainer
} from './styles'

import Checkbox from './Checkbox'

const AddNoteForm = (props: AddNoteProps & FormikProps<FormValues>) => {
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = props;
  return (
    <Wrapper>
      <Form onSubmit={handleSubmit}>
        <Header>{strings.incidentDetail.addNoteForm.header}</Header>
        {values.isPublic && <CheckboxContainer>
          <Checkbox name="isPublicComment" checked={values.isPublicComment}>
            IncidentCODE Comment
          </Checkbox>
        </CheckboxContainer>}
        <Label>{strings.incidentDetail.addNoteForm.label}</Label>
        <Textarea
          name="comment"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.comment}
          placeholder={strings.incidentDetail.addNoteForm.placeholder}
          disabled={isSubmitting}
        />
        <Button type="submit" disabled={isSubmitting} gradient={true}>
          Add
        </Button>
      </Form>
      <CancelButton onClick={props.onHideForm} gradient={false}>
        {strings.incidentDetail.buttons.cancel}
      </CancelButton>
    </Wrapper>
  );
};

export default AddNoteForm;
