import React, { useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import styled from 'styled-components/macro'
import {
  WallIncident,
  Organization,
  WallHistoryRequest,
  HistoryType,
  ChatActionType,
  IncidentEscortState,
  IncidentLocation
} from 'incident-code-core'
import { AxiosError } from 'axios'

import { colors, fonts, images, strings } from '../../../../constants'
import { orgService } from '../../../../services/orgs-service'
import { historyService } from '../../../../services/history-service'
import { Status } from './Status'
import Intrado from '../../../../components/Intrado/Intrado'
import { chatService } from '../../../../services/chat-service'

interface subHeaderIncident extends WallIncident {
  publicCode?: string
}

interface ISubHeaderProps extends RouteComponentProps {
  incident: subHeaderIncident
  status: IncidentEscortState
  showNotificationForm(): void
  locationData?: IncidentLocation
}

const SubHeader: React.FC<ISubHeaderProps> = props => {
  const [organization, setOrganization] = useState<Organization>({})
  const { incident, locationData } = props

  useEffect(() => {
    if (incident.organization) {
      orgService
        .getOrganizationInfoById(incident.organization.toString())
        .then(response => setOrganization(response.data))
        .catch((error: AxiosError) => console.error(error.message))
    }
  }, [incident.organization])

  const onCloseDetail = () => {
    const { id, isResolved } = incident
    if (isResolved) {
      window.close()
    }

    const payload: WallHistoryRequest = {
      incidentId: `${id}`,
      type: HistoryType.Chat,
      action: ChatActionType.Closed
    }
    chatService.removeParticipants(incident)
    historyService.create(payload).then(() => window.close())
  }

  const showNotificationForm = () => props.showNotificationForm()

  return (
    <Container>
      <Back>
        <CloseButton onClick={onCloseDetail} />
      </Back>
      <Incident>
        <Title>
          <TitleText>
            #{incident.id.toString().slice(-5)}
            {' -  Virtual escort'}
            {organization.hasOwnProperty('name') ? ` - ${organization.name}` : ' '}
            {!!incident.publicCode && ` - ${incident.publicCode}`}
          </TitleText>
        </Title>
      </Incident>
      <Status status={props.status} />
      {(locationData || (incident && incident.location)) && (
        <IntradoWrap>
          <Intrado locationData={locationData} incident={incident} />
        </IntradoWrap>
      )}
      <NotifyUsers>
        <NotificationButton onClick={showNotificationForm}>
          {strings.incidentDetail.buttons.notifyUsers}
        </NotificationButton>
      </NotifyUsers>
    </Container>
  )
}

export const SubHeaderWithRouter = withRouter(SubHeader)

const Container = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.black};
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

const TitleText = styled.h2`
  font-family: ${fonts.defaultFont};
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: -0.2px;
  text-align: center;
  color: ${colors.ghostWhite};
`

const Back = styled.div`
  flex-grow: 1;
  align-items: flex-start;
`

const Incident = styled.div`
  flex-grow: 4;
  align-items: flex-start;
`

const NotifyUsers = styled.div`
  flex-grow: 1;
`

const CloseButton = styled.button`
  display: flex;
  align-items: flex-start;
  background-color: inherit;
  background: url(${images.close}) right no-repeat;
  width: 30px;
  border: none;
  outline: none;
  color: ${colors.midnight};
  font-size: 16px;
  height: 32px;
  &:hover {
    cursor: pointer;
  }
`

const NotificationButton = styled.button`
  display: flex;
  align-items: flex-end;
  background: linear-gradient(279deg, #503bff, #a11de7);
  color: ${colors.ghostWhite};
  border: none;
  font-size: 16px;
  font-family: ${fonts.defaultFont};
  padding: 10px;
`

const IntradoWrap = styled.div`
  flex-grow: 1;
`
