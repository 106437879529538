import React, { useState } from 'react'
import { Popover, PopoverBody } from 'reactstrap'
import { colors, strings, fonts } from '../../constants'
import styled from 'styled-components/macro'
import { withRouter } from 'react-router'
import { notificationService } from "../../services/notification-service"


const Intrado = ({ incident, locationData  }: any) => {

  const [popoverOpen, setPopoverOpen] = useState<boolean>(false)
  const [isLoading, setLoader] = useState<boolean>(true)
  const [err, setError] = useState<Object>(null)
  const [intradoData, setIntradoData] = useState<any>({})

  const handleClick =  async () => {
    try {
      setError(null)
      if (isLoading) {
        setPopoverOpen(!popoverOpen)
        const location = { type: 'Point', coordinates: incident.location.coordinates }
        if (locationData && locationData.locations && locationData.locations.length > 0){
          location.coordinates = [locationData.locations[locationData.locations.length - 1].location.lng,locationData.locations[locationData.locations.length - 1].location.lat];
        }
        const data = await notificationService.intradoInfo(location)
        setIntradoData(data.data)
        setLoader(!isLoading)
      } else {
        setLoader(!isLoading)
        setPopoverOpen(!popoverOpen)
      } 
    }catch(error){
      setError(error.message)
    }
  }

  return (
    <ModelArea>
      <span className="text-white">
        <IntradoButton id="intradoPopOver" onClick={handleClick}>
          {strings.incidentDetail.buttons.intrado}
        </IntradoButton>
      </span>

      <StyledPopover
        placement="bottom"
        isOpen={popoverOpen}
        target="intradoPopOver"
        toggle={handleClick}
      >
        <PopoverBody>
          { !isLoading && Object.keys(intradoData).length > 1 ? (
            <ModelBox>
              {Object.keys(intradoData).map((key : any, index: number) => {
                if(key !== 'ns:ResponderType'){return(
                  <ol key={key}>
                    <hr />
                    <li><b>Agency</b> : {key}</li>
                    <li><b>AgencyName</b>: {intradoData[key].AgencyName}</li>
                    <li><b>ContactInfo</b>: {intradoData[key].ContactInfo}</li>
                    <li><b>ContactInfo2</b>: {intradoData[key].ContactInfo2}</li>
                    <li><b>City</b>: {intradoData[key].City}</li>
                    <li><b>State</b>: {intradoData[key].State}</li>
                    <li><b>Instructions</b>: {intradoData[key].Instructions}</li> 
                  </ol>
                )}
              })}
            </ModelBox>
          ) : err !== null ? (
            <p>Error : {err}</p>
          ): !isLoading ? <p>Data Not Found!</p> : <p>Loading...</p>}
        </PopoverBody>
      </StyledPopover>
    </ModelArea>
  )
}

const StyledPopover = styled(Popover)`
  min-width: 328px;
  background-color: red;
`

const ModelArea = styled.div``
const ModelBox = styled.div`
  height: 500px;
  overflow-x: auto;
`

const IntradoButton = styled.button`
  display: flex;
  align-items: flex-end;
  background: linear-gradient(279deg, #503bff, #a11de7);
  color: ${colors.ghostWhite};
  border: none;
  font-size: 16px;
  font-family: ${fonts.defaultFont};
  padding: 10px;
  cursor: pointer;
`
export default withRouter(Intrado)
