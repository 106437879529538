import { EventEmitter } from 'events'
import { Dict, WallIncident, ChatMessage, Incident } from 'incident-code-core'
// import { Channel } from 'twilio-chat/lib/channel'
// import { Client as TwilioClient } from 'twilio-chat/lib/client'
// import { Message as TwilioMessage } from 'twilio-chat/lib/message'

import {
  Conversation,
  Client as TwilioClient,
  Message as TwilioMessage
} from '@twilio/conversations'

import { incidentService } from './incident-service'
import { twilioAuthService } from './twilio-auth-service'
import { ApiService } from './api-service'

export const ChatListener = new EventEmitter()
export const ClientMessageAddedEvent = 'clientMessageAdded'

export interface ChatEventHandler {
  onMessageAdded: (messages: ChatMessage) => void
  onJoined: () => void
  onConnectionStateChanged: () => void
  onMemberJoined: () => void
}

export class ChatService extends ApiService {
  client: TwilioClient
  channel: Conversation
  incident: WallIncident
  memberMap: Dict<string> = {}
  eventHandler: ChatEventHandler
  readonlyMode: boolean

  async init(incident?: WallIncident) {
    this.incident = incident
    if (this.client) return this.client

    const token = await twilioAuthService.getToken(this.incident, true)
    if (token === null) return
    this.client = new TwilioClient(token)
    this.client.on('tokenAboutToExpire', this.refreshToken)
    this.client.on('messageAdded', this.onClientSentMessage)
    this.client.on('connectionStateChanged', this.onConnectionStateChanged)

    return this.client
  }

  async initChat(eventHandler: ChatEventHandler, connectionHandler?: any): Promise<void> {
    this.eventHandler = eventHandler
    this.readonlyMode = !!(this.incident && this.incident.isResolved)
    if (!this.readonlyMode && this.channel == null) {
      return await this.initChannel(connectionHandler && connectionHandler)
    }
  }

  async close() {
    if (this.client) {
      await this.client.shutdown()
      this.removeParticipants(this.incident)
    }
  }

  async leave() {
    if (this.channel) {
      await this.channel.leave()
      this.removeParticipants(this.incident)
    }
  }

  send(message: string): void {
    if (this.readonlyMode) return
    this.sendInternal(message)
  }

  async loadMessages(): Promise<ChatMessage[]> {
    let messages: ChatMessage[] = []
    if (this.readonlyMode) {
      const incidentChat = await incidentService.getChat(this.incident.id)
      if (incidentChat && incidentChat.data.messages) {
        messages = incidentChat.data.messages.reverse()
      }
    } else {
      if (this.incident && this.incident.hasChat) {
        if (this.channel == null) {
          await this.initChannel()
        }
        if (this.channel) {
          if (this.channel.status === 'joined' && this.eventHandler.onJoined) {
            this.eventHandler.onJoined()
          }
          const twilioMessages = await this.channel.getMessages()
          messages = twilioMessages.items.map(this.convertTwilioMessage).reverse()
        }
      }
    }

    if (messages.length > 0) {
      return messages
    }
    return []
  }

  getUserId(memberId: string): string {
    return this.memberMap[memberId]
  }

  private async sendInternal(message: string): Promise<void> {
    if (this.channel == null) {
      await this.initChannel()
    }
    this.channel.sendMessage(message)
  }

  private async initChannel(handleConnectionStatus?: any): Promise<any> {
    try {
      const x = await incidentService.startChat(this.incident)
    } catch (error) {
      console.log('startChat', error)
    }
    try {
      this.channel = await this.client.getConversationByUniqueName(this.incident.id.toString())
    } catch (error) {
      try {
        await this.post(['wall', 'migrate', 'addParticipants'], {
          channelId: this.incident.id.toString(),
          orgId: this.incident.organization
        })
      } catch (error) {
        console.log('addParticipants error: ', error)
      }
      this.channel = await this.client.getConversationByUniqueName(this.incident.id.toString())
    }
    this.channel.on('messageAdded', this.onMessageAddedInternal)
    this.channel.on('participantJoined', this.onMemberJoined)
    if (this.channel && this.channel.status !== 'joined') {
      try {
        await this.channel.join()
        handleConnectionStatus && handleConnectionStatus(true)
      } catch (error) {
        handleConnectionStatus && handleConnectionStatus(false)
      }
    }
    const members = await this.channel.getParticipants()
    members.forEach(member => {
      this.memberMap[member.sid] = member.identity
    })
    this.channel.setAllMessagesRead()

    window.addEventListener('beforeunload', async () => {
      await this.channel.leave()
    })

    return this.channel
  }

  private onMessageAddedInternal = (message: TwilioMessage) => {
    // the receive the message, make it is read
    this.channel.setAllMessagesRead()
    if (this.eventHandler.onMessageAdded) {
      this.eventHandler.onMessageAdded(this.convertTwilioMessage(message))
    }
  }

  private onConnectionStateChanged = (ev: any) => {}

  private onMemberJoined = (ev: any) => {}

  private refreshToken = async () => {
    const token = await twilioAuthService.getToken(this.incident, true)
    this.client.updateToken(token)
  }

  private convertTwilioMessage = (message: any): ChatMessage => {
    const { address, location } = message.attributes

    return {
      id: message.sid,
      content: message.body,
      createdAt: message.state.timestamp,
      from: message.author, // this.memberMap[message.author],
      address,
      location
    }
  }

  private onClientSentMessage = (message: TwilioMessage) => {
    if (this.client.user.identity !== message.author) {
      ChatListener.emit(ClientMessageAddedEvent, message)
    }
  }

  public removeParticipants(incident: Incident): Promise<any> {
    return this.post(['wall', 'migrate', 'removeParticipants'], {
      channelId: incident.id.toString(),
      orgId: incident.organization
    })
  }
}

export const chatService = new ChatService()
