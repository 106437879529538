import { IncidentType } from 'incident-code-core'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import styled from 'styled-components/macro'
import { colors, strings } from '../../../constants'
import { INCIDENT_SCROLL_CONTAINER } from '../../../constants/scroll'
import { configService } from '../../../services/config-service'
import { IncidentCard } from './IncidentCard'
import { EscortCard } from './EscortCard'
import { FilterContext } from '../../../contexts/FilterContext'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectIncidentState,
  selectFilteredIncidents,
  incidentActions
} from '../../../store/incidents'
import { notificationActions, notificationSelectors } from '../../../store'
const { markNotificationsForIncidentAsRead } = notificationActions
const { selectIsThereNotifications } = notificationSelectors

export const Cards: React.FunctionComponent<any> = props => {
  const dispatch = useDispatch()

  const { organizations } = React.useContext(FilterContext)
  const incidents = useSelector(selectFilteredIncidents)
  const { pagination } = useSelector(selectIncidentState)
  const isThereNotifications = useSelector(selectIsThereNotifications)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [search, setSearch] = React.useState('')

  const handleCardClick = (id: string) => {
    toast.dismiss(id)
    dispatch(markNotificationsForIncidentAsRead({ incidentId: id }))
  }

  function renderIncidentList(): React.ReactNode {
    props.setTotalPage(pagination.totalPage)
    return incidents.map((incident: any, i) => {
      const organization = organizations
        .filter(({ id }) => id === incident.organization)
        .map(org => org.name)
        .toString()

      const isPlaying = true
      const title = configService.config!.getIncidentCategory(incident.category!).display

      if (incident.type === IncidentType.Normal) {
        return (
          <IncidentCard
            id={`${i + 1}`}
            incident={incident}
            title={title}
            organization={organization}
            key={incident.id}
            isPlaying={isPlaying}
            onClick={() => handleCardClick(incident.id)}
          />
        )
      } else {
        return (
          <EscortCard
            id={`${i + 1}`}
            incident={incident}
            organization={organization}
            key={incident.id}
            isPlaying={isPlaying}
            onClick={() => handleCardClick(incident.id)}
          />
        )
      }
    })
  }

  const loadMoreIncident = () => {
    if (pagination.hasMore && !pagination.loadingNext)
      dispatch(incidentActions.getIncidents({ loadNext: true, filter: props.filter }))
  }

  if (!incidents || !pagination) return null

  const { hasMore, totalPage } = pagination
  const hasIncidents = !!incidents.length
  props.setTotalPage(totalPage)

  const ScrollContainer: any = isThereNotifications
    ? styled(props.showClosedIncidents ? 'div' : InfiniteScroll)`
        -webkit-animation: argh-my-eyes 1s infinite;
        -moz-animation: argh-my-eyes 1s infinite;
        animation: argh-my-eyes 1s infinite;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 20px 0 15px 30px;
      `
    : styled(props.showClosedIncidents ? 'div' : InfiniteScroll)`
        background-color: ${colors.background};
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 20px 0 15px 30px;
      `

  return (
    <Container id={INCIDENT_SCROLL_CONTAINER}>
      {props.children}
      {hasIncidents ? (
        props.showClosedIncidents ? (
          <ScrollContainer>{renderIncidentList()}</ScrollContainer>
        ) : (
          <ScrollContainer useWindow={false} hasMore={hasMore} loadMore={loadMoreIncident}>
            {renderIncidentList()}
          </ScrollContainer>
        )
      ) : (
        <Title>{strings.noIncidents}</Title>
      )}
    </Container>
  )
}

const Container = styled.div`
  flex: 1;
  overflow-y: scroll;
`

const Title = styled.h2`
  color: ${colors.white};
`
