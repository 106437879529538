import { IncidentType } from 'incident-code-core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components/macro'
import { toast } from 'react-toastify'

import { colors } from '../../constants'
import { incidentService } from '../../services/incident-service'
import { ChatListener, chatService, ClientMessageAddedEvent } from '../../services/chat-service'
import { openNewWindow } from '../../utils/browser-utils'

import {
  Loader,
  Filters,
  StatusTab,
  ChatNotification,
  ActionModal,
  NotifyUser
} from '../../components'
import { SidePanel } from './SidePanel'
import { FilterContext } from '../../contexts/FilterContext'

import VisibilityContextProvider from '../../contexts/VisibilityContext'
import { Cards } from './Cards'
import { incidentActions } from '../../store'
import { firebaseActions } from '../../store/firebase'
import { selectIncidentState, selectFilteredIncidents } from '../../store/incidents'

export const Incidents: React.FC<void> = () => {
  const dispatch = useDispatch()
  const incidents = useSelector(selectFilteredIncidents)
  const {
    loading,
    filters: { type: incidentType, isResolved: showIsResolved }
  } = useSelector(selectIncidentState)

  // const [isDataLoad, setDataLoad] = React.useState<boolean>(false)
  // const [showClosedIncidents, setShowClosedIncidents] = React.useState<boolean>(false)
  const { selectedOrganization } = React.useContext(FilterContext)
  const [openModal, setOpenModal] = React.useState(false)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [totalPage, setTotalPage] = React.useState(1)
  const [search, setSearch] = React.useState('')
  const [disableButton, setDisableButton] = React.useState(false)
  const [callbackArray, setCallbackArray] = React.useState([])
  // const [incidentType, setType] = React.useState<IncidentType | null>(null)

  const toggleModal = React.useCallback(() => setOpenModal(!openModal), [openModal])

  // const handleType = React.useCallback(filterByIncidentType => {
  //   incidentService.clearFetchAllInterval()
  //   setType(filterByIncidentType)
  // }, [])

  const handleType = (incidentType: string) =>
    dispatch(incidentActions.setFilters({ type: incidentType }))
  const handleIsResolved = (isResolved: boolean) =>
    dispatch(incidentActions.setFilters({ isResolved }))

  const handlePagination = (e: any, currentPage: number) => {
    e.preventDefault()
    if (currentPage >= 1) {
      setCurrentPage(currentPage)
      setDisableButton(true)
      dispatch(
        incidentActions.getIncidents({
          loadNext: true,
          currentPage: currentPage,
          filter: search
        })
      )
      setTimeout(() => setDisableButton(false), 2000)
    }
  }

  const handleSearch = (e: any) => {
    e.persist()
    const value = e.target.value
    setSearch(value)
    setCurrentPage(1)
    callbackArray.forEach((cb: any) => {
      clearTimeout(cb)
    })

    setCallbackArray([
      setTimeout(() => {
        dispatch(
          incidentActions.getIncidents({
            loadNext: true,
            currentPage: 1,
            filter: value
          })
        )
      }, 500)
    ])
  }

  React.useEffect(() => {
    dispatch(incidentActions.getIncidents({ currentPage: showIsResolved ? 1 : 0, filter: search }))
    dispatch(firebaseActions.subscribe())
    chatService.init()
    // IncidentListener.on(FetchIncidentsEvent, (result: QueryResult) => {
    //   const isDataLoad: boolean = result && result.data != null
    //   setDataLoad(isDataLoad)
    // })

    return function cleanup() {
      chatService.close()
      // IncidentListener.removeListener(FetchIncidentsEvent, (isLoaded: boolean) =>
      //   setDataLoad(isLoaded),
      // )
    }
  }, [])

  React.useEffect(() => {
    ChatListener.removeAllListeners()
    ChatListener.on(ClientMessageAddedEvent, message => {
      toast.info(
        <ChatNotification
          title={message.conversation.channelState.uniqueName}
          message={message.state.body}
        />,
        {
          onClick: () => {
            const incident = incidentService.incidents.get(
              message.conversation.channelState.uniqueName
            )
            openNewWindow(`incident/${incident.id}`)
          }
        }
      )
    })
  }, [])

  // React.useEffect(() => {
  //   ;(async function getIncidents() {
  //     await incidentService.getAllIncidents(
  //       true,
  //       true,
  //       showClosedIncidents,
  //       selectedOrganization,
  //       incidentType,
  //     )
  //   })()
  //   return () => incidentService.clearFetchAllInterval()
  // }, [showClosedIncidents, selectedOrganization, incidentType])

  if (loading) {
    return <Loader />
  }

  return (
    <Container>
      <VisibilityContextProvider>
        <IncidentsContainer>
          <Filters
            showNotificationForm={toggleModal}
            incidentType={incidentType}
            handleIncidentType={handleType}
            showPagination={showIsResolved}
            currentPage={currentPage}
            totalPage={totalPage}
            handlePagination={handlePagination}
            searchValue={search}
            handleSearch={handleSearch}
            disableButton={disableButton}
          >
            <StatusTab showClosed={showIsResolved} setShowClosed={handleIsResolved} />
          </Filters>
          <Cards
            showClosedIncidents={showIsResolved}
            selectedOrganization={selectedOrganization}
            incidentType={incidentType}
            currentPage={currentPage}
            setTotalPage={setTotalPage}
            filter={search}
          />
        </IncidentsContainer>
        {/* <SidePanel type={IncidentType.Normal} incidents={incidents} /> */}
        <ActionModal open={openModal}>
          <NotifyUser onHideForm={toggleModal} />
        </ActionModal>
      </VisibilityContextProvider>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: calc(100vh - 52px);
  background-color: ${colors.black};
`

const IncidentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
