import styled from 'styled-components/macro'
import { colors, fonts } from '../../constants'

export const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
`
export const Form = styled.form`
  display: flex
  flex: 1
  flex-direction: column
  flex-wrap: wrap
`
export const Textarea = styled.textarea`
  padding: 16px 20px;
  background: none;
  border: none;
  border-bottom: 1px solid ${colors.midnight};
  font-size: 18px;
  color: ${colors.black};
  width: 100%;
  margin-bottom: 14px;
  resize: none;
  ::placeholder {
    color: ${colors.midnight};
  }
`

export const CheckboxContainer = styled.div`
  margin-top: 20px;
`
export interface IButton {
  gradient: boolean
}

export const Button = styled.button`
  background: ${(props: IButton) => (props.gradient ? `linear-gradient(279deg, #503bff, #a11de7)` : colors.midnight)};
  margin-top: 10px;
  width: 100%;
  color: ${colors.ghostWhite};
  border: none;
  font-size: 16px;
  font-family: ${fonts.defaultFont};
  padding: 14px 0;
  &:first-of-type {
    margin-top: 40px;
  }
`
export const CancelButton = styled.button`
  background: ${(props: IButton) => (props.gradient ? `linear-gradient(279deg, #503bff, #a11de7)` : colors.midnight)};
  margin-top: 10px;
  width: 100%;
  color: ${colors.ghostWhite};
  border: none;
  font-size: 16px;
  font-family: ${fonts.defaultFont};
  padding: 14px 0;
`
export const Header = styled.h3`
  color: ${colors.black};
  margin-bottom: 20px;
`

export const Label = styled.label`
  color: ${colors.black};
  font-size: 13px;
  margin-top: 18px;
  margin-bottom: 11px;
`