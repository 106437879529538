import * as Yup from 'yup';
import { HistoryType } from 'incident-code-core';
import { withFormik } from 'formik'

import { historyService } from '../../services/history-service'
import { AddNoteProps, FormValues } from './interfaces'
import AddNoteForm from './Form'


const addNoteSchema = Yup.object().shape({
  comment: Yup.string().trim().required(),
  isPublicComment: Yup.boolean(),
});

export const AddNote = withFormik<AddNoteProps, FormValues>({
  mapPropsToValues: ( props ) => ({ title: "", comment: "", isPublicComment: false, isPublic: props.isPublic }),
  validationSchema: addNoteSchema,
  handleSubmit({ comment, isPublicComment }: FormValues, { props, setSubmitting, resetForm }) {
    const payload = {
      incidentId : props.incidentId,
      type: isPublicComment ? HistoryType.Comment : HistoryType.Note,
      content: comment
    }
    historyService.create(payload).then(() => {
      props.onHideForm();
      resetForm({})
    })

    setSubmitting(false)
  }
})(AddNoteForm);