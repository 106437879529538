import { ApiService } from './api-service'
import { Notification } from '../components/NotificationIcon/NotificationIcon'

interface INotificationService {
  subscribe(token: string): Promise<void>
  getNotifications(): Promise<Notification[]>
  markAsRead(notification: Notification): Promise<void>
}

class NotificationService extends ApiService implements INotificationService {
  public subscribe = async (token: String): Promise<any> => {
    const url = ['wall', 'subscription']
    const payload = { token }

    return await this.patch(url, payload)
  }

  public unsubscribe = async (token: String): Promise<void> => {
    const url = ['wall', 'unsubscribe']
    const payload = { token }
    await this.patch(url, payload)
    return
  }

  public markAsRead = async (notification: Notification): Promise<void> => {
    const id = notification.id || notification.data.storedId
    const url = ['wall', 'notifications', id.toString()]
    await this.patch(url)
  }

  public getNotifications = async (): Promise<Notification[]> => {
    const url = ['wall', 'notifications']
    return await this.get(url, { limit: 10 }).then(response => response.data)
  }

  public pnForChat = async (data: any): Promise<void> => {
    const url = ['wall', 'pnForChat']
    this.post(url, data)
  }

  public intradoInfo = async (data: any): Promise<any> => {
    const url = ['psap', 'info']
    return await this.post(url, data)
  }
}

export const notificationService = new NotificationService()
