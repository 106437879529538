import React from 'react'
import { Col, Row } from "reactstrap"
import moment from "moment"
import {
  HistoryType,
  ReportMessage,
  ChatReport
} from "incident-code-core"
import styled from "styled-components/macro"

interface IProps {
  chat: ChatReport
}

export const Histories: React.FC<IProps> = ({ chat }) => {
  const isHistory = (m: ReportMessage): boolean => {
    const historyType = [
      HistoryType.Chat,
      HistoryType.Note,
      HistoryType.Incident,
      HistoryType.Escort,
      HistoryType.Share,
      HistoryType.Close,
      HistoryType.Comment];

    return !!historyType.find(t => t === m.type);
  }

  const chatDateFormat = 'MM/DD/YYYY LT'

  const isUSer = (id: string) => {
    return id.charAt(0) === 'U';
  }

  return (
    <div>
      <div className="pb-2 font-weight-bold">
        Message History
      </div>
      <ChatContainer className="p-2 mb-4">
        {chat &&
          chat.messages.map((m: ReportMessage) => {
              if (isHistory(m)) {
                return <div className="mb-2" key={m.id.toString()}>
                  <div>
                    {m.content ? m.content : m.title}
                  </div>
                  <small>
                    {moment(m.createdAt).format(chatDateFormat)}
                  </small>
                </div>
              }

              if (m.type === HistoryType.Video) {
                return <Row key={m.id.toString()}>
                  <Col md={5}></Col>
                  <Col md={7}>
                    <MessageBox isUser={isUSer(m.from)} className="p-2 mt-4">
                      <div>
                        <img src={m.thumbnailUrl} height={100} alt="video history" />
                      </div>
                      <div>
                        #{m.videoShortId}
                      </div>
                      <div className="text-right">
                        <small>
                          {moment(m.createdAt).format(chatDateFormat)}
                        </small>
                      </div>
                    </MessageBox>
                    <Row>
                      <Col md={9}>
                        {isUSer(m.from) ?
                          <small>{m.address}</small> :
                          <MessageBoxPointer className="ml-3" isUser={isUSer(m.from)}/>}
                      </Col>
                      <Col md={3} className="d-flex flex-row-reverse">
                        {isUSer(m.from) ? <MessageBoxPointer className="mr-3" isUser={isUSer(m.from)}/> : ''}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              }

              return <Row key={m.id.toString()}>
                {isUSer(m.from) ? <Col md={5}></Col> : ''}
                <Col md={7}>
                  <MessageBox isUser={isUSer(m.from)} className="p-2 mt-4">
                    <div>
                      {m.content}
                    </div>
                    <div className="text-right">
                      <small>
                        {moment(m.createdAt).format(chatDateFormat)}
                      </small>
                    </div>
                  </MessageBox>
                  <Row>
                    <Col md={9}>
                      {isUSer(m.from) ?
                        <small>{m.address}</small> :
                        <MessageBoxPointer className="ml-3" isUser={isUSer(m.from)}/>}
                    </Col>
                    <Col md={3} className="d-flex flex-row-reverse">
                      {isUSer(m.from) ? <MessageBoxPointer className="mr-3" isUser={isUSer(m.from)}/> : ''}
                    </Col>
                  </Row>
                </Col>
              </Row>
            }
          )}
      </ChatContainer>
    </div>
  )
}

interface IMessageBoxProps {
  isUser: boolean
}

const ChatContainer = styled.div`
  border: 1px solid #ddd;
  position: relative
`

const MessageBox = styled.div<IMessageBoxProps> `
  background: ${props => props.isUser ? '#ededed' : '#f1f2fa'}
`

const MessageBoxPointer = styled.div<IMessageBoxProps>`
  background: ${props => props.isUser ? 'linear-gradient(45deg, #ffffff 0%,#ffffff 50%,#ededed 50%,#ededed 100%)'
    : 'linear-gradient(135deg, #f1f2fa 0%,#f1f2fa 50%,#ffffff 50%,#ffffff 100%);'};
  width: 20px;
  height: 20px;
  position: inline;
`