import React from 'react'
import styled from 'styled-components/macro'
import { Col, Button, Row, Input } from 'reactstrap'
import { IncidentType } from 'incident-code-core'

import { colors, strings } from '../../constants'
import { Navbar, NotificationButton, OrganizationsDropdown } from '../'

interface IFilterProps {
  isNotificationButtonShown?: boolean
  children: React.ReactNode | React.ReactNode[]
  incidentType: IncidentType
  showNotificationForm?(): void
  handleIncidentType(type: IncidentType): void
  showPagination?: boolean
  totalPage?: number
  currentPage?: number
  handlePagination?(event: any, currentPage: any): void
  searchValue?: string
  handleSearch?(event: any): void
  disableButton?: boolean
}

export const Filters: React.FC<IFilterProps> = props => (
  <FilterContainer>
    <FilterTopContainer>
      <Row className="ml-0 mr-0" style={{ alignItems: 'center' }}>
        <Col>
          <Navbar incidentType={props.incidentType} handleIncidentType={props.handleIncidentType} />
          {props.children}
        </Col>
        <Col className="text-right pb-4 pt-4">
          <NotificationButton showNotificationForm={props.showNotificationForm} />
        </Col>
      </Row>
    </FilterTopContainer>
    <FilterBottomContainer>
      <div className="pt-2 pb-2 mr-4">{strings.organization}</div>
      <OrganizationsDropdown />
      {props.showPagination ? (
        <div className="text-right col" style={{ right: '1%' }}>
          <Input
            type={'search'}
            placeholder={'phone or user name'}
            value={props.searchValue}
            style={{ display: 'inline-block', width: '15%' }}
            onChange={props.handleSearch}
          ></Input>{' '}
          <Button
            style={{ display: 'inline-block', marginRight: '4px' }}
            onClick={e => {
              props.handlePagination(e, props.currentPage - 1)
            }}
            disabled={props.currentPage === 1 || props.disableButton}
          >
            Previous
          </Button>
          <Input
            type={'number'}
            min={1}
            max={10}
            defaultValue={props.totalPage}
            value={props.currentPage}
            style={{ display: 'inline-block', width: '5%' }}
            onChange={e => {
              props.handlePagination(e, Number(e.target.value))
            }}
          ></Input>{' '}
          <Input
            type={'text'}
            disabled={true}
            defaultValue={`of ${props.totalPage}`}
            value={`of ${props.totalPage}`}
            style={{ display: 'inline-block', width: '5%', backgroundColor: 'white' }}
          ></Input>{' '}
          <Button
            disabled={props.currentPage === props.totalPage || props.disableButton}
            onClick={e => {
              props.handlePagination(e, props.currentPage + 1)
            }}
          >
            Next
          </Button>
        </div>
      ) : (
        ''
      )}
    </FilterBottomContainer>
  </FilterContainer>
)

const FilterContainer = styled.div`
  font-size: 15px;
`

const FilterTopContainer = styled.div`
  background: ${colors.darkerGray};
`

const FilterBottomContainer = styled.div`
  background: ${colors.background};
  color: ${colors.white};
  padding: 30px 20px 20px;
  display: flex;
  flex-direction: row;
`
