import React from 'react'
import { Row } from 'reactstrap'
import moment from 'moment'
import { IncidentPassiveLog, IncidentPassive } from 'incident-code-core'
import styled from 'styled-components/macro'
import { OrganizationProcedure } from 'incident-code-core/src/types'

interface IProps {
  passiveLog: IncidentPassive
}

export const AuditLog: React.FC<IProps> = ({ passiveLog }) => {
  const dateFormat = 'MM/DD/YYYY LT'
  const procedure = passiveLog.procedure as OrganizationProcedure

  const parseTimer = (time: number) => {
    const temp = moment.duration(90, 'seconds')
    let timeStr = ''
    if (temp.hours()) {
      timeStr += temp.hours() + ' hour' + (temp.hours() > 1 ? 's ' : ' ')
    }
    if (temp.minutes()) {
      timeStr += temp.minutes() + ' minute' + (temp.minutes() > 1 ? 's ' : ' ')
    }
    if (temp.seconds()) {
      timeStr += temp.seconds() + ' second' + (temp.seconds() > 1 ? 's ' : ' ')
    }
    return timeStr
  }

  return (
    <div>
      {
        procedure &&
        <>
          <div className="pb-2 font-weight-bold">{procedure.name} Procedure History</div>
            <div className="pb-2">Timer: {parseTimer(procedure.timer)}</div>
            <LogContainer className="p-2 mb-4">
              {passiveLog.log &&
                passiveLog.log.map((m: IncidentPassiveLog, i) => {
                  return (
                    <Row className="pl-3 mb-2" key={i}>
                      <div>
                        <div>
                          {m.action} {m.actionName && `: ${m.actionName}`}
                        </div>

                       <small>{moment(m.updatedAt).format(dateFormat)}</small>
                      </div>
                    </Row>
                  )
                })
              }
            </LogContainer>
        </> 
      }
    </div>
  )
}

const LogContainer = styled.div`
  border: 1px solid #ddd;
  position: relative;
`
