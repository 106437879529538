import { WallIncident, IncidentType, IncidentChat, IncidentVideo } from 'incident-code-core'
import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router'
import styled from 'styled-components/macro'
import { AxiosError } from 'axios'

import { useVideoService } from '../../services/video-service-hook'
import { incidentService } from '../../services/incident-service'
import { SidePanel } from '../../components/SidePanels/TipSidePanel'
import { colors } from '../../constants'
import { Header, Loader, ShareForm } from '../../components'
import FilterContextProvider from '../../contexts/FilterContext'

import Videos from './components/Videos'
import { Actions } from './components/Actions'
import { Player, SubHeader } from './components'

import {
  ActionModal,
  NotifyUser,
  AddNote,
  CloseIncident,
  Chat,
  IncidentDetail
} from '../../components/'

import { Legend } from '../Escort/components/Legend'
import { GeneratePublicCodeCheckbox } from '../../components/GeneratePublicCodeCheckbox'
import { Wrapper } from '../../components/ShareForm/styles'

interface IncidentParams {
  id: string
}

interface PublicCodeIncident extends WallIncident {
  publicCode?: string
}

export const Incident = () => {
  let history = useHistory()
  const params = useParams<IncidentParams>()
  const [incident, setIncident] = useState<PublicCodeIncident>({ type: IncidentType.Normal, videos: [] })
  const [videos, setVideos] = useState<IncidentVideo[]>([])
  const [chat, setChat] = useState<IncidentChat>()
  const [openModal, setOpenModal] = useState(false)
  const [addNoteModal, setAddNoteModal] = useState(false)
  const [shareModal, setShareModal] = useState(false)
  const [closeModal, setCloseModal] = useState(false)

  const { state, playNext, changeCurrentVideo } = useVideoService(videos)
  const incidentHasOneVideo =
    !Boolean(state.service.allVideos) || state.service.allVideos.length === 1

  const toggleModal = React.useCallback(() => setOpenModal(!openModal), [openModal])
  const toggleAddNoteModal = React.useCallback(() => setAddNoteModal(!addNoteModal), [addNoteModal])
  const toggleCloseModal = React.useCallback(() => setCloseModal(!closeModal), [closeModal])
  const toggleShareModal = React.useCallback(() => setShareModal(!shareModal), [shareModal])
  const updateIncident = (update: any) => {
    setIncident({ ...incident, ...update })
  }

  useEffect(() => {
    incidentService
      .getIncidentById(params.id)
      .then(response => {
        if (response.data.type === IncidentType.Escort) {
          return history.push(`/escort/${params.id}`)
        }
        setIncident(response.data)
        setVideos(response.data.videos)
      })
      .catch((error: AxiosError) => console.error(error.message))

    ;(async () => {
      const result = await incidentService.getIncidentChat(params.id)
      setChat(result)
    })()
  }, [params.id, history])

  useEffect(() => {
    const { isResolved } = incident
    const getVideos = async (id: string) => {
      const { data: incident } = await incidentService.getIncidentById(params.id)
      setIncident(incident)
      const videosCurrently = await incidentService.getVideos(id)
      if (videosCurrently.length > videos.length) {
        setVideos(videosCurrently)
      }
    }
    if (!isResolved) {
      const intervalId = setInterval(() => {
        getVideos(params.id)
      }, 8000)
      return () => clearInterval(intervalId)
    }
  }, [params.id, videos, incident])

  useEffect(() => {
    document.body.style.overflow = 'scroll'
  })

  const { activeOrgUser } = incident as any;
  const loading = !incident.id || !!activeOrgUser

  if (loading)
    return (
      <Loader message={activeOrgUser && `This incident is being handled by: ${activeOrgUser}`} />
    )

  return (
    <TotalContainer>
      <Header />
      <SubHeader incident={incident} showNotificationForm={toggleModal} />
      <RootContainer>
        <IncidentsContainer>
          <Container>
            <div>
              <IncidentContainer>
                <IncidentDetail incident={incident} />
                <Player onFinish={playNext} video={state.video} loop={incidentHasOneVideo} />
              </IncidentContainer>
              <Videos
                videos={state.service.allVideos?.map((v, i) => ({ ...v, uploadId: i + 1 }))}
                onPreviewClick={changeCurrentVideo}
                currentVideo={state.video}
              />
            </div>
            <Actions
              incident={incident}
              showGenerateLinkOverlay={toggleShareModal}
              showCloseIncidentOverlay={toggleCloseModal}
              showAddNoteOverlay={toggleAddNoteModal}
            />
          </Container>
        </IncidentsContainer>
        <Side>
          <SidePanel
            type={incident.type}
            home={false}
            incidents={[incident]}
            showContent={true}
            chat={chat}
          >
            <Legend />
          </SidePanel>
          <Chat incident={incident} isClosed={incident.isResolved} />
        </Side>
      </RootContainer>
      <ActionModal open={openModal}>
        <FilterContextProvider>
          <NotifyUser onHideForm={toggleModal} />
        </FilterContextProvider>
      </ActionModal>
      <ActionModal open={addNoteModal}>
        <AddNote onHideForm={toggleAddNoteModal} incidentId={incident.id.toString()} isPublic={!!incident.publicCode} />
      </ActionModal>
      <ActionModal open={shareModal}>
        <Wrapper>
          <GeneratePublicCodeCheckbox
            modalHandler={toggleShareModal}
            incident={incident}
            handleData={updateIncident}
          />
          <ShareForm
            onHideForm={toggleShareModal}
            incidentId={incident.id.toString()}
            inverted={true}
          />
        </Wrapper>
      </ActionModal>
      <ActionModal open={closeModal}>
        <CloseIncident onHideForm={toggleCloseModal} incidentId={incident.id.toString()} />
      </ActionModal>
    </TotalContainer>
  )
}

const TotalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const RootContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${colors.black};
`

const IncidentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 50%;
`

const Container = styled.div`
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
`

const IncidentContainer = styled.div`
  min-height: 370px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
`

const Side = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
